<template>
  <VContainer fluid>
    <DialogWrapper
      v-model="dialog"
      fullscreen
      @click:outside="closeModal"
    >
      <StaffForm
        v-if="dialog"
        :edit="edit"
        :edit-user="activeUser"
        @close="closeModal"
        @save="handleSave"
      />
    </DialogWrapper>
    <DialogWrapper
      v-model="deleteDialog"
      width="536"
    >
      <DeleteStaffForm
        v-if="deleteDialog"
        :staff="toDelete"
        :deleting="deleting"
        @delete="handleDelete"
        @cancel="deleteDialog = false"
      />
    </DialogWrapper>

    <VRow>
      <VCol cols="6">
        <h1 class="tt-text-headline-1">
          Все сотрудники
        </h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTTextField
          v-model="filter.search_text"
          large
          append-icon="fal fa-search"
          label="Поиск"
          placeholder="ФИО или телефон"
          clearable
          data-test="staff-search-staff"
          @input="handleTextSearch"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VRow>
          <VCol
            cols="6"
            data-test="select-tags"
          >
            <SelectTags
              v-if="needStaffTags"
              v-model="selectedTags"
              label="Тег"
              :items="tags"
            />
            <TTSelect
              v-else
              v-model="filter.location_id"
              large
              :items="locations"
              item-value="id"
              hide-details="auto"
              item-text="name"
              label="Локация"
              attach
              :menu-props="{ auto : true }"
              class="tt-select-attach"
              placeholder="Выбор локации"
              data-test="staff-select-location"
              @change="handleLocation"
            >
              <template #selection="{item,disabled}">
                <TextTemplate
                  class="v-select__selection v-select__selection--comma"
                  :disabled="disabled"
                  :text="item.name"
                  :show-prefix="!item.is_active && !!item.id"
                />
              </template>
              <template #item="{item}">
                <TextTemplate
                  :text="item.name"
                  :show-prefix="!item.is_active && !!item.id"
                />
              </template>
            </TTSelect>
          </VCol>
          <VCol cols="3">
            <TTSelect
              v-model="staffType"
              large
              :items="staffTypeList"
              item-text="name"
              label="Роль"
              placeholder="Выбор должности"
              data-test="staff-select-position"
              @change="handleStaffType"
            />
          </VCol>
          <VCol cols="3">
            <TTSelect
              v-model="filter.state"
              large
              :items="stateType"
              item-text="name"
              label="Статус"
              placeholder="Выбор статуса"
              data-test="staff-select-status"
              @change="handleStatus"
            />
          </VCol>
        </VRow>
      </VCol>
      <VCol class="pb-0 pt-1 flex-grow-0">
        <VMenu
          offset-y
          :nudge-bottom="8"
          bottom
          max-width="466"
          content-class="v-menu-shadow"
          :close-on-content-click="false"
          left
        >
          <template #activator="{ on }">
            <div class="mt-10">
              <VBadge
                :value="selectedFilters"
                dark
                color="black"
                :content="selectedFilters"
                overlap
              >
                <TTBtn
                  fab
                  depressed
                  large
                  color="tt-secondary-danger"
                  :ripple="false"
                  class="table-menu-button"
                  data-test="report-meeting-button-show-menu"
                  v-on="on"
                >
                  <VIcon
                    size="19"
                    color="black"
                  >
                    fal fa-sliders-h
                  </VIcon>
                </TTBtn>
              </VBadge>
            </div>
          </template>
          <VCard class="v-menu-card">
            <VCardText class="pa-6">
              <VRow>
                <VCol
                  cols="12"
                  data-test="select-groups"
                >
                  <SelectTags
                    v-if="needStaffTags"
                    v-model="selectedGroups"
                    label="Группа"
                    :items="tags"
                  />
                </VCol>
                <VCol
                  class="pt-3 pb-3"
                  cols="9"
                >
                  <p class="tt-text-body-2 mb-0">
                    Поиск по HR’у, руководителю, наставнику
                  </p>
                </VCol>
                <VCol
                  v-if="filter.connected_staff_search.length"
                  cols="3"
                  class="pt-3 text-right"
                >
                  <span
                    class="tt-dark-blue--text"
                    @click="resetSideStaffSearch"
                  >
                    Сбросить
                  </span>
                </VCol>
                <VCol
                  class="pt-0 pb-2"
                  cols="12"
                >
                  <TTTextField
                    v-model="filter.connected_staff_search"
                    placeholder="Поиск по ФИО"
                    large
                    hide-details
                    single-line
                    append-icon="fal fa-search"
                    data-test="side-staff-input-search"
                    @input="handleSideStaffTextSearch"
                  />
                </VCol>
                <VCol
                  class="pt-0 pb-6"
                  cols="12"
                >
                  <p class="tt-light-mono-46--text mb-0">
                    Введите имя и фамилию HR’а, руководителя или
                    наставника, чьих подопечных вы хотите увидеть
                  </p>
                </VCol>
                <VCol
                  class="pt-0 pb-3"
                  cols="6"
                >
                  <p class="tt-text-body-2 mb-0">
                    Период выхода на работу
                  </p>
                </VCol>
                <VCol
                  v-if="searchWorkPeriod.length"
                  cols="6"
                  class="pt-0 text-right"
                >
                  <span
                    class="tt-dark-blue--text"
                    @click="handleWorkPeriodChange([])"
                  >
                    Сбросить
                  </span>
                </VCol>
                <VCol
                  cols="12"
                  class="py-0"
                >
                  <SRangePicker
                    append-icon="fal fa-calendar-alt"
                    placeholder="Выберите период"
                    :value="searchWorkPeriod"
                    :max-date="dateNow"
                    single-line
                    hide-details="auto"
                    data-test="report-meeting-search-period"
                    @input="handleWorkPeriodChange"
                  />
                </VCol>
                <VCol
                  class="pt-6 pb-3"
                  cols="6"
                >
                  <p class="tt-text-body-2 mb-0">
                    Период добавления
                  </p>
                </VCol>
                <VCol
                  v-if="searchAddPeriod.length"
                  cols="6"
                  class="pt-6 text-right"
                >
                  <span
                    class="tt-dark-blue--text"
                    @click="handleAddPeriodChange([])"
                  >
                    Сбросить
                  </span>
                </VCol>
                <VCol
                  cols="12"
                  class="pt-0 pb-3"
                >
                  <SRangePicker
                    append-icon="fal fa-calendar-alt"
                    placeholder="Выберите период"
                    :value="searchAddPeriod"
                    :max-date="dateNow"
                    single-line
                    hide-details="auto"
                    data-test="report-meeting-search-period"
                    @input="handleAddPeriodChange"
                  />
                </VCol>
              </VRow>
            </VCardText>
          </VCard>
        </VMenu>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTDataTable
          :items="staff"
          :headers="staffHeaders"
          :loading="!isLoaded"
          :items-per-page="-1"
          item-key="created"
          class="clickable"
          extra-tall
          hide-default-footer
          data-test="staff-table"
        >
          <template #header.last_name="{ header }">
            {{ header.text }}
            <span>({{ totalStaff }})</span>
          </template>
          <template #item.last_name="{item}">
            <div
              class="d-flex align-center cursor--pointer"
              @click="$router.push(`/staff/${item.id}`)"
            >
              <NewSAvatar
                :img="item.photo_url"
                :name="`${item.last_name} ${item.first_name}`"
              />
              <div class="pr-2 pl-3 d-flex flex-column justify-center">
                <div>
                  {{ item.last_name }} {{ item.first_name }}
                  <TTTooltip
                    v-if="!item.is_email_confirmed"
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <VIcon
                        color="warning"
                        size="16"
                        class="mx-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        fas fa-exclamation-circle
                      </VIcon>
                    </template>
                    <span>E-mail не подтвержден</span>
                  </TTTooltip>
                </div>
                <div class="tt-black--text text--lighten-2">
                  <Tags
                    v-if="needStaffTags"
                    :list="listTags"
                  />
                  <TTTooltip
                    v-else
                    bottom
                    max-width="300"
                  >
                    <template #activator="{ on, attrs }">
                      <p
                        v-bind="attrs"
                        class="tt-black--text text--lighten-2 ellipsis-two-lines ellipsis mb-0"
                        v-on="on"
                      >
                        {{ item.location.name }}<span v-if="item.position">, {{ item.position }}</span>
                      </p>
                    </template>
                    <p class="mb-0">
                      {{ item.location.name }}<span v-if="item.position">, {{ item.position }}</span>
                    </p>
                  </TTTooltip>
                </div>
              </div>
              <VSpacer v-if="!needStaffTags" />
              <VCol
                v-if="!needStaffTags"
                class="pa-0 flex-grow-0"
              >
                <VProgressCircular
                  v-if="item.progress > 0"
                  rotate="270"
                  :size="36"
                  :width="2"
                  :value="item.progress"
                  color="primary"
                >
                  <span class="text--black">{{ item.level }}</span>
                </VProgressCircular>
              </VCol>
            </div>
          </template>
          <template #item.phone="{item}">
            <div class="d-flex fill-height align-center">
              {{ item.phone }}
            </div>
          </template>
          <template #item.started="{item}">
            <div class="d-flex fill-height align-center">
              {{ formatDate(item.started) }}
            </div>
          </template>
          <template #item.created="{item}">
            <div class="d-flex align-center fill-height justify-space-between">
              <div>
                {{ formatDate(item.created) }}
              </div>
              <div class="ml-4">
                <VMenu
                  v-if="!item.is_auto_login"
                  content-class="v-menu-shadow"
                  offset-y
                  left
                >
                  <template #activator="{ on }">
                    <TTBtn
                      fab
                      small
                      depressed
                      color="transparent tt-ghost--text"
                      :ripple="false"
                      class="table-menu-button"
                      data-test="staff-button-show-menu"
                      v-on="on"
                    >
                      <VIcon size="19">
                        fal fa-ellipsis-h
                      </VIcon>
                    </TTBtn>
                  </template>
                  <VCard class="v-menu-card">
                    <VList
                      dense
                    >
                      <VListItem
                        data-test="staff-button-send-invite"
                        class="custom-menu-item"
                        @click="sendInvite(item.id)"
                      >
                        <VListItemIcon>
                          <VIcon
                            size="19"
                            color="tt-black"
                          >
                            fal fa-comment-alt-check
                          </VIcon>
                        </VListItemIcon>
                        <VListItemContent>Отправить инвайт</VListItemContent>
                      </VListItem>
                      <VListItem
                        v-if="item.page_link"
                        class="custom-menu-item"
                        data-test="staff-button-copy-link"
                        @click="copyLink(item.page_link)"
                      >
                        <VListItemIcon>
                          <VIcon
                            size="19"
                            color="tt-black"
                          >
                            fal fa-copy
                          </VIcon>
                        </VListItemIcon>
                        <VListItemContent>Скопировать ссылку</VListItemContent>
                      </VListItem>
                      <VListItem
                        data-test="staff-button-open-staff-form"
                        class="custom-menu-item"
                        @click="toggleEdit(item)"
                      >
                        <VListItemIcon>
                          <VIcon
                            size="19"
                            color="tt-black"
                          >
                            fal fa-edit
                          </VIcon>
                        </VListItemIcon>
                        <VListItemContent>Редактировать</VListItemContent>
                      </VListItem>
                      <VListItem
                        data-test="staff-button-open-delete-form"
                        class="custom-menu-item"
                        @click="openDelete(item)"
                      >
                        <VListItemIcon>
                          <VIcon
                            size="19"
                            color="error"
                          >
                            fal fa-trash-alt
                          </VIcon>
                        </VListItemIcon>
                        <VListItemContent class="error--text">
                          Удалить
                        </VListItemContent>
                      </VListItem>
                    </VList>
                  </VCard>
                </VMenu>
              </div>
            </div>
          </template>
          <template #item.ended="{item}">
            <div
              v-if="!staff.is_fired"
              class="d-flex fill-height align-center"
            >
              {{ formatDate(item.ended) }}
            </div>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
    <VRow v-if="isLoaded && !fullLoad">
      <VCol>
        <div v-intersect.quiet="getMore" />
      </VCol>
    </VRow>
    <VRow v-if="!isLoaded && !fullLoad">
      <VCol align="center">
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import debounce from 'lodash/debounce';
import { get } from 'lodash';
import * as snamiApi from '@/services/backend/snami';
import { formatDate } from '@/utils';
import { pageLocationToken } from '@/services';
import { staffStates } from '@/constants';
import StaffForm from '@/components/forms/StaffForm.vue';
import DeleteStaffForm from '@/components/forms/DeleteStaffForm.vue';
import NewSAvatar from '@/components/ui/NewSAvatar.vue';
import localStorageInterface from '@/services/localStorage/_utils';
import SRangePicker from '@/components/ui/SRangePicker.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import TextTemplate from '@/components/shared/TextTemplate.vue';
import SelectTags from '@/components/shared/SelectTags.vue';
import Tags from '@/components/shared/Tags.vue';
// import { locationRepository } from '@/services/backend/snami/repositories';

const pageStaffTypeToken = localStorageInterface('snami_page_staff_type');
const pageStaffStatusToken = localStorageInterface('snami_page_staff_status');
const pageStaffTextSearchToken = localStorageInterface('snami_page_staff_text');
const pageStaffSideStaffToken = localStorageInterface('snami_page_staff_side_staff');
const pageStaffWorkPeriodToken = localStorageInterface('snami_page_staff_work_period');
const pageStaffAddPeriodToken = localStorageInterface('snami_page_staff_add_period');

export default {
  name: 'Staff',
  components: {
    TextTemplate,
    DialogWrapper,
    DeleteStaffForm,
    StaffForm,
    NewSAvatar,
    SRangePicker,
    SelectTags,
    Tags,
  },
  data() {
    return {
      snackbar: {
        id: null,
        icon: '',
        title: '',
        msg: '',
        timeout: 6000,
        color: '#00D358',
        success: false,
      },
      dialog: false,
      deleteDialog: false,
      deleting: false,
      toDelete: {},
      edit: false,
      activeUser: {},
      staffType: pageStaffTypeToken.get() || 'is_candidate',
      staffTypeList: [
        {
          value: 'all',
          name: 'Все роли',
        },
        {
          value: 'is_candidate',
          name: 'Адаптируемые сотрудники',
        },
        {
          value: 'is_mentor',
          name: 'Руководители',
        },
        {
          value: 'is_tutor',
          name: 'Наставники',
        },
        {
          value: 'is_hr',
          name: 'HR',
        },
      ],
      stateType: [
        {
          value: staffStates.ACTIVE,
          name: 'Действующие',
        },
        {
          value: staffStates.INVITED,
          name: 'Приглашенные',
        },
        {
          value: staffStates.ADAPTING,
          name: 'Адаптирующиеся',
        },
        {
          value: staffStates.ADAPTED,
          name: 'Адаптированные',
        },
        {
          value: staffStates.WITHOUT_ADAPTATION,
          name: 'Без адаптации',
        },
        {
          value: staffStates.FIRED,
          name: 'Уволенные',
        },
      ],
      filter: {
        location_id: null,
        search_text: pageStaffTextSearchToken.get() || '',
        is_candidate: null,
        is_mentor: null,
        is_tutor: null,
        is_hr: null,
        state: pageStaffStatusToken.get() || staffStates.ACTIVE,
        connected_staff_search: pageStaffSideStaffToken.get() || '',
        started_from: '',
        started_till: '',
        created_from: '',
        created_till: '',
      },
      dateNow: this.$dayjs().format('YYYY-MM-DD'),
      searchWorkPeriod: pageStaffWorkPeriodToken.get() || [],
      searchAddPeriod: pageStaffAddPeriodToken.get() || [],
      inviteCondition: staffStates.ADAPTED,
      sort: 'DEFAULT',
      staff: [],
      default_location: {
        id: null,
        name: 'Все локации',
      },
      staffHeaders: [],
      locations: [],
      invite: {},
      page: {},
      isLoaded: false,
      tags: [
        {
          text: 'Очень большое название чтобы проверить',
          value: 0,
        },
        {
          text: 'Еще одно очень большое название',
          value: 2,
        },
      ],
      selectedTags: [0, 2],
      selectedGroups: [],
      listTags: ['Очень большое название чтобы проверить', 'первый', 'второй'],
    };
  },
  computed: {
    currentPage() {
      return this.page.n || 0;
    },
    totalStaff() {
      return this.page.total_item || 0;
    },
    fullLoad() {
      return this.staff.length >= this.totalStaff;
    },
    selectedFilters() {
      let totalFilters = 0;
      if (this.filter.connected_staff_search) totalFilters += 1;
      if (this.searchWorkPeriod.length) totalFilters += 1;
      if (this.searchAddPeriod.length) totalFilters += 1;
      return totalFilters;
    },
    needStaffTags() {
      return this.$di.ff.get('staff_tags');
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.hash) {
          if (this.$route.hash.includes('#add')) {
            this.dialog = true;
          } else if (this.$route.hash.includes('#state')) {
            const routeHash = this.$route.hash.split('=');
            // eslint-disable-next-line prefer-destructuring
            this.filter.state = routeHash[1];
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.handleSearch = debounce(this.getData, 500);
    this.calculateHeaders();
    this.$repositories.location.list({ filter: {} }).then((r) => {
      const { data } = r.data;
      this.locations = data;
      this.locations.unshift(this.default_location);

      const locationIds = this.locations.map((item) => item.id);
      let loc;
      // проверяем, правильные ли данные нам пришли: сравниваем с locationIds и есть ли локация с таким id
      if (this.$route.query.locationId !== undefined
        && locationIds.indexOf(parseInt(this.$route.query.locationId, 10)) !== -1) {
        // в loc записывает id локации из урла
        loc = this.$route.query.locationId;
        pageLocationToken.set(loc);
      } else {
        // если пришел не верный id те локации с таким id нет, то достаем из локалстораджа id
        loc = pageLocationToken.get();
      }
      this.filter.location_id = loc ? parseInt(loc, 10) : null;
      this.getData();
    }).catch((e) => console.warn(e));
  },
  mounted() {
    this.$root.$on('addUser', this.addUser);
  },
  beforeDestroy() {
    this.$root.$off('addUser', this.addUser);
  },
  methods: {
    addUser() {
      this.edit = false;
      this.activeUser = {};
      this.dialog = true;
    },
    formatDate,
    defineRole() {
      const staffTypes = this.staffTypeList.map((type) => type.value);
      if (this.staffType === 'all') {
        staffTypes.forEach((type) => {
          this.filter[type] = null;
        });
      } else {
        staffTypes.forEach((type) => {
          if (type === this.staffType) {
            this.filter[type] = 'true';
          } else {
            this.filter[type] = null;
          }
        });
      }
    },
    handleLocation() {
      pageLocationToken.set(this.filter.location_id);
      this.handleSearch();
    },
    handleStaffType() {
      pageStaffTypeToken.set(this.staffType);
      this.handleSearch();
    },
    handleStatus() {
      pageStaffStatusToken.set(this.filter.state);
      this.handleSearch();
    },
    handleTextSearch() {
      pageStaffTextSearchToken.set(this.filter.search_text);
      this.handleSearch();
    },
    handleSideStaffTextSearch() {
      pageStaffSideStaffToken.set(this.filter.connected_staff_search);
      this.handleSearch();
    },
    resetSideStaffSearch() {
      this.filter.connected_staff_search = '';
      pageStaffSideStaffToken.set(this.filter.connected_staff_search);
      this.handleSearch();
    },
    handleWorkPeriodChange(period) {
      this.searchWorkPeriod = period;
      pageStaffWorkPeriodToken.set(this.searchWorkPeriod);
      this.handleSearch();
    },
    handleAddPeriodChange(period) {
      this.searchAddPeriod = period;
      pageStaffAddPeriodToken.set(this.searchAddPeriod);
      this.handleSearch();
    },
    calculateHeaders() {
      const headers = [
        {
          text: 'Сотрудники',
          align: 'start',
          sortable: false,
          value: 'last_name',
        },
        {
          text: 'Телефон', value: 'phone', sortable: false, width: 140,
        },
        {
          text: 'Дата выхода', value: 'started', sortable: false, width: 130,
        },
      ];
      if (this.needStaffTags) {
        headers.splice(1, 0, {
          text: 'Должность', value: 'position', sortable: false, width: 140,
        });
      }
      if (this.filter.state !== 'FIRED') {
        headers.push({
          text: 'Дата добавления', value: 'created', sortable: false, width: this.needStaffTags ? 160 : 180,
        });
      } else {
        headers.push({
          text: 'Дата увольнения', value: 'ended', sortable: false, width: this.needStaffTags ? 140 : 180,
        });
      }
      this.staffHeaders = headers;
    },
    toggleEdit(item) {
      this.edit = true;
      this.activeUser = item;
      this.dialog = true;
    },
    closeModal() {
      this.edit = false;
      this.activeUser = {};
      this.dialog = false;
      if (this.$route.hash.includes('#add')) {
        this.$router.push({ ...this.$route, hash: '' });
      }
    },
    handleSave({ response, user }) {
      // проверяем на то что сотрудник новый и является ли он адаптируемым
      if (!this.edit && user.is_candidate) {
        // Костыль для разделения ошибок отправки смс,
        // P.S. когда бэк оживет, нужен либо текст от бека целиком, либо код ошибок
        const isInvalidCountrySending = response.sms_error_message.includes('страну невозможна');
        if (response.sms_error) {
          if (isInvalidCountrySending) {
            this.$root.$emit('showNotifier', {
              title: 'СМС не отправлено',
              msg: 'Смс не отправлено. Обратитесь в раздел о продукте',
              timeout: -1,
              type: 'warning',
            });
          } else {
            const submitCallback = async () => {
              await this.sendInvite(response.id);
            };
            this.$root.$emit('showNotifier', {
              title: 'СМС не отправлено',
              msg: 'Сотрудник добавлен, но при отправке СМС возникли проблемы. \n'
                + 'Отправить повторно?',
              timeout: -1,
              type: 'warning',
              submitAction: submitCallback,
              cancelAction: () => {},
              cancelText: 'Не отправлять',
            });
          }
        } else {
          this.$root.$emit('showNotifier', {
            title: 'Сотрудник успешно добавлен',
            msg: 'Сотруднику отправлено СМС с ссылкой для входа в приложение.',
            timeout: 6000,
            type: 'success',
          });
        }
      }
      this.closeModal();
      this.getData();
    },
    async sendInvite(id) {
      try {
        const { data } = await snamiApi.customerInviteSend({ staff_id: id });
        this.invite = data.data;
        if (this.invite) {
          this.$root.$emit('showNotifier', {
            title: 'Сотрудник успешно добавлен',
            msg: 'Сотруднику отправлено СМС с ссылкой для входа в приложение.',
            timeout: 6000,
            type: 'success',
          });
        } else {
          const submitCallback = async () => {
            await this.sendInvite(id);
          };
          this.$root.$emit('showNotifier', {
            title: 'СМС не отправлено',
            msg: 'Сотрудник добавлен, но при отправке СМС возникли проблемы. \n'
              + 'Отправить повторно?',
            timeout: -1,
            type: 'warning',
            submitAction: submitCallback,
            cancelAction: () => {},
            cancelText: 'Не отправлять',
          });
        }
        this.getData();
      } catch (e) {
        const message = get(e, 'response.data.error.message', 'Возникла ошибка при отправке приглашения');
        const submitCallback = async () => {
          await this.sendInvite(id);
        };
        this.$root.$emit('showNotifier', {
          title: 'СМС не отправлено',
          msg: message,
          timeout: -1,
          type: 'warning',
          submitAction: submitCallback,
          cancelAction: () => {},
          cancelText: 'Не отправлять',
        });
      }
    },
    copyLink(link) {
      navigator.clipboard.writeText(link).then(() => {
        this.$root.$emit('showNotifier', {
          title: 'Ссылка скопирована',
          timeout: 6000,
          type: 'success',
        });
      });
    },
    handleDelete(replaceId) {
      this.deleting = true;
      this.$repositories.staff.fire({
        data: {
          id: this.toDelete.id,
          replace_staff_id: replaceId,
        },
      }).then(() => {
        const index = this.staff.findIndex((item) => item.id === this.toDelete.id);
        if (index !== -1) {
          this.staff.splice(index, 1);
        } else {
          this.getData();
        }
        this.deleteDialog = false;
        this.toDelete = {};
      }).catch((e) => {
        console.warn(e);
        this.$root.$emit('showNotifier', {
          title: 'Не удалось уволить сотрудника',
          timeout: 6000,
          type: 'error',
        });
      }).finally(() => {
        this.deleting = false;
      });
    },
    openDelete(item) {
      this.deleteDialog = true;
      this.toDelete = item;
    },
    // здесь начинается часть, благодаря которой происходит автоподгруза при скролле
    handleData(data) {
      this.staff = [...this.staff, ...data];
    },
    getData() {
      this.defineRole();
      this.calculateHeaders();
      this.filter.started_from = this.searchWorkPeriod[0] || '';
      this.filter.started_till = this.searchWorkPeriod[1] || '';
      this.filter.created_from = this.searchAddPeriod[0] || '';
      this.filter.created_till = this.searchAddPeriod[1] || '';
      // TODO Временно включено для QA
      // if (this.needStaffTags) {
      // delete this.filter.location_id;
      // }
      this.staff = [];
      this.page = {};
      this.isLoaded = false;
      const { filter, sort } = this;
      this.$repositories.staff.list({ data: { filter, sort } }).then((r) => {
        const { data, page } = r.data;
        this.handleData(data);
        this.page = page;
      }).catch((e) => {
        console.warn(e);
      }).finally(() => {
        this.isLoaded = true;
      });
    },
    getMore(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.isLoaded = false;
        const { filter, sort } = this;
        this.$repositories.staff.list({ data: { filter, sort, page: this.currentPage + 1 } }).then((r) => {
          const { data, page } = r.data;
          this.handleData(data);
          this.page = page;
        }).catch((e) => {
          console.warn(e);
        }).finally(() => {
          this.isLoaded = true;
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">

.custom-snackbar {
  .custom-snackbar__title {
    font-size: 16px;
  }

  .custom-snackbar__text {
    font-size: 14px;
  }

  .custom-snackbar__button {
    font-size: 14px;
  }
}
</style>
