// eslint-disable-next-line import/prefer-default-export
export const getPasteText = (event) => {
  if (window?.clipboardData?.getData) { // IE
    return window.clipboardData.getData('Text');
  }
  if (event?.clipboardData?.getData) {
    return event.clipboardData.getData('text/plain');
  }
  return '';
};
