<template>
  <VContainer
    fluid
    class="pa-4"
  >
    <VRow class="my-0">
      <VCol cols="1" />
      <VCol cols="10">
        <VRow>
          <VCol
            lg="9"
            sm="12"
          >
            <VForm
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <VRow
                justify="center"
                class="my-0"
              >
                <VCol
                  class="pb-6"
                >
                  <h1
                    class="tt-text-headline-1"
                    data-test="staff-form-title"
                  >
                    {{ edit ? 'Редактирование' : 'Добавление нового' }} сотрудника
                  </h1>
                  <div
                    v-if="globalErrorMessage"
                    class="error--text mt-2"
                  >
                    {{ globalErrorMessage }}
                  </div>
                </VCol>
              </VRow>
              <h2 class="mb-6 tt-text-title-2">
                Основное
              </h2>
              <VRow>
                <VCol cols="4">
                  <div class="mb-3 tt-text-body-2">
                    Номер телефона
                  </div>
                  <PhoneNumber
                    attach
                    :value="user.phone"
                    :error="validation('phone').status"
                    :error-messages="validation('phone').value"
                    @input="handleChange('phone', $event)"
                    @change="createCheck"
                  />
                </VCol>
                <VCol
                  v-if="isPhoneChecked || edit"
                  cols="4"
                >
                  <TTSelect
                    class="tt-select-attach"
                    attach
                    large
                    label="Локация"
                    :menu-props="{ auto : true }"
                    placeholder="Локация"
                    :value="activeLocation"
                    :items="locations"
                    :disabled="locationDisabled"
                    item-text="name"
                    item-value="id"
                    data-test="staff-form-location-select"
                    @change="handleLocation"
                  >
                    <template #selection="{item,disabled}">
                      <TextTemplate
                        class="v-select__selection v-select__selection--comma"
                        :disabled="disabled"
                        :text="item.name"
                        :show-prefix="!item.is_active && !!item.id"
                      />
                    </template>
                    <template #item="{item}">
                      <TextTemplate
                        :text="item.name"
                        :show-prefix="!item.is_active && !!item.id"
                      />
                    </template>
                  </TTSelect>
                </VCol>
                <VCol
                  v-if="activeLocation"
                  cols="4"
                >
                  <TTTextField
                    large
                    :value="user.email"
                    label="Email"
                    placeholder="example@yourcompany.ru"
                    data-test="staff-form-email-input"
                    :error="validation('email').status"
                    :error-messages="validation('email').value"
                    @input="v => handleChange('email',v)"
                  />
                </VCol>
              </VRow>
              <template v-if="activeLocation">
                <VRow>
                  <VCol>
                    <TTTextField
                      large
                      :value="user.first_name"
                      label="Имя"
                      placeholder="Имя"
                      data-test="staff-form-first-name-input"
                      :error="validation('first_name').status"
                      :error-messages="validation('first_name').value"
                      @input="v => handleChange('first_name',v)"
                    />
                  </VCol>
                  <VCol>
                    <TTTextField
                      large
                      :value="user.last_name"
                      label="Фамилия"
                      placeholder="Фамилия"
                      data-test="staff-form-last-name-input"
                      :error="validation('last_name').status"
                      :error-messages="validation('last_name').value"
                      @input="v => handleChange('last_name',v)"
                    />
                  </VCol>
                  <VCol>
                    <TTTextField
                      large
                      :value="user.middle_name"
                      label="Отчество (при наличии)"
                      placeholder="Отчество"
                      data-test="staff-form-middle-name-input"
                      :error="validation('middle_name').status"
                      :error-messages="validation('middle_name').value"
                      @input="v => handleChange('middle_name',v)"
                    />
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    v-if="edit"
                    cols="4"
                  >
                    <div class="mb-3 tt-text-body-2">
                      Загрузите фото
                    </div>
                    <UploadAvatar
                      class="mr-6"
                      :value="user.photo_url"
                      mandatory
                      data-test="staff-form-upload-avatar"
                      :error="validation('photo_base64').status"
                      :error-messages="validation('photo_base64').value"
                      @input="v => handleChange('photo_base64',v)"
                    />
                  </VCol>
                  <VCol cols="4">
                    <SDatePicker
                      attach
                      append-icon="fal fa-calendar-alt"
                      label="Дата рождения"
                      placeholder="Дата рождения"
                      :value="user.birthday"
                      hide-details="auto"
                      single-line
                      :max-date="dateNow"
                      data-test="staff-form-birthday"
                      :error="validation('birthday').status"
                      :error-messages="validation('birthday').value"
                      @input="v => handleChange('birthday',v)"
                    />
                  </VCol>
                  <VCol cols="4">
                    <div class="mb-3 tt-text-body-2">
                      Пол
                    </div>
                    <VBtnToggle
                      v-model="user.sex"
                      class="day-of-week-btns"
                      data-test="staff-form-sex-group"
                      label="Пол"
                    >
                      <VBtn
                        :value="true"
                        data-test="staff-form-sex-man"
                      >
                        мужской
                      </VBtn>
                      <VBtn
                        :value="false"
                        data-test="staff-form-sex-woman"
                      >
                        женский
                      </VBtn>
                    </VBtnToggle>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="12">
                    <TTTextField
                      large
                      :value="user.position"
                      label="Должность"
                      placeholder="Должность"
                      data-test="staff-form-position-input"
                      :error="validation('position').status"
                      :error-messages="validation('position').value"
                      @input="v => handleChange('position',v)"
                    />
                  </VCol>
                </VRow>
                <VRow>
                  <VCol>
                    <VCheckbox
                      v-if="!edit"
                      v-model="user.is_candidate"
                      data-test="staff-form-is-candidate-checkbox"
                      label="Адаптируемый"
                      hide-details="auto"
                      color="tt-black"
                      class="mt-0 pt-0"
                      :ripple="false"
                    />
                    <VCheckbox
                      v-model="user.is_tutor"
                      data-test="staff-is-tutor-checkbox"
                      label="Наставник"
                      hide-details="auto"
                      color="tt-black"
                      class="pt-0"
                      :ripple="false"
                    />
                  </VCol>
                </VRow>
                <template v-if="showTagInput">
                  <VRow>
                    <VCol>
                      <VDivider />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol>
                      <h2
                        class="tt-text-title-2"
                        data-test="staff-form-tags"
                      >
                        Теги
                      </h2>
                    </VCol>
                  </VRow>
                  <VRow dense>
                    <VCol>
                      <div
                        class="tt-text-body-2 tt-light-mono-46--text"
                        data-test="staff-form-tags-caption"
                      >
                        Рассказать, что это за теги и зачем они нужны
                      </div>
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol>
                      <Combobox
                        v-model.trim="tags"
                        :items="tagsItems"
                        data-test="staff-form-tags-combobox"
                        :search-input.sync="search"
                      />
                    </VCol>
                  </VRow>
                </template>
                <VRow>
                  <VCol>
                    <VDivider />
                  </VCol>
                </VRow>
                <template v-if="user.is_candidate">
                  <VRow>
                    <VCol>
                      <h2 class="mb-6 tt-text-title-2">
                        Команда
                      </h2>
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol cols="4">
                      <TTAutocomplete
                        label="HR"
                        placeholder="HR"
                        large
                        attach
                        no-rotate
                        append-icon="fal fa-search"
                        :value="user.hr"
                        :loading="hr.loading"
                        :items="hr.list"
                        :search-input.sync="hr.search"
                        hide-no-data
                        hide-details="auto"
                        single-line
                        clearable
                        return-object
                        item-text="name"
                        item-value="id"
                        data-test="staff-form-hr"
                        :error="validation('hr_id').status"
                        :error-messages="validation('hr_id').value"
                        :menu-props="{ 'maxHeight' : 130 }"
                        @change="v => handleChange('hr', v, 'hr_id')"
                      />
                    </VCol>
                    <VCol cols="4">
                      <TTAutocomplete
                        label="Руководитель"
                        placeholder="Руководитель"
                        large
                        no-rotate
                        attach
                        append-icon="fal fa-search"
                        :value="user.mentor"
                        :loading="mentor.loading"
                        :items="mentor.list"
                        :search-input.sync="mentor.search"
                        hide-no-data
                        clearable
                        hide-details="auto"
                        single-line
                        return-object
                        item-text="name"
                        item-value="id"
                        data-test="staff-form-mentor"
                        :error="validation('mentor_id').status"
                        :error-messages="validation('mentor_id').value"
                        :menu-props="{ 'maxHeight' : 130 }"
                        @change="v => handleChange('mentor', v, 'mentor_id')"
                      />
                    </VCol>
                    <VCol cols="4">
                      <TTAutocomplete
                        label="Наставник"
                        placeholder="Наставник"
                        large
                        no-rotate
                        clearable
                        attach
                        append-icon="fal fa-search"
                        :value="user.tutor"
                        :loading="tutor.loading"
                        :items="tutor.list"
                        :search-input.sync="tutor.search"
                        hide-no-data
                        hide-details="auto"
                        single-line
                        return-object
                        item-text="name"
                        item-value="id"
                        data-test="staff-form-tutor"
                        :error="validation('tutor_id').status"
                        :error-messages="validation('tutor_id').value"
                        :menu-props="{ 'maxHeight' : 130 }"
                        @change="v => handleChange('tutor', v, 'tutor_id')"
                      />
                    </VCol>
                  </VRow>
                  <VDivider class="mb-4 mt-9" />
                  <h2 class="mb-6 tt-text-title-2">
                    Условия оффера
                  </h2>
                  <VRow>
                    <VCol
                      cols="4"
                    >
                      <TTTextField
                        v-mask="'#########'"
                        large
                        :value="metaData.salary || ''"
                        label="Зарплата"
                        placeholder="Зарплата"
                        data-test="staff-form-salary-input"
                        :error="validation('salary').status"
                        :error-messages="validation('salary').value"
                        @input="v => handleMetaChange('salary', parseInt(v, 10))"
                      />
                    </VCol>
                    <VCol
                      cols="8"
                    >
                      <VRow>
                        <VCol
                          cols="12"
                          class="d-flex pa-0"
                        >
                          <VCol cols="4">
                            <TTTextField
                              large
                              :value="metaData.vacation_days || ''"
                              label="Кол-во дней отпуска"
                              placeholder="Дней отпуска"
                              data-test="staff-form-vacation-days-input"
                              :error="validation('vacation_days').status"
                              :error-messages="validation('vacation_days').value"
                              @input="v => handleMetaChange('vacation_days',v)"
                            />
                          </VCol>
                          <VCol cols="4">
                            <SDatePicker
                              attach
                              append-icon="fal fa-calendar-alt"
                              :value="user.started"
                              label="Дата выхода"
                              placeholder="Дата выхода"
                              hide-details="auto"
                              format="YYYY-MM-DD"
                              single-line
                              display-format="DD.MM.YYYY"
                              data-test="staff-form-started"
                              :error="validation('started').status"
                              :error-messages="validation('started').value"
                              @input="v => handleChange('started',v)"
                            />
                          </VCol>
                          <VCol cols="4">
                            <CtxTimePicker
                              attach
                              append-icon="fal fa-clock"
                              :value="user.started_time"
                              label="Время выхода"
                              placeholder="--:--"
                              hide-details="auto"
                              single-line
                              :minute-interval="1"
                              data-test="staff-form-started-time"
                              :error="validation('started_time').status"
                              :error-messages="validation('started_time').value"
                              @input="v => handleChange('started_time',v)"
                            />
                          </VCol>
                        </VCol>
                      </VRow>
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol cols="12">
                      <TTTextarea
                        v-model="metaData.work_conditions"
                        single-line
                        :rows="4"
                        :maxlength="1000"
                        data-test="staff-form-work-conditions"
                        label="Условия/оборудование"
                        placeholder="Условия/оборудование"
                      />
                    </VCol>
                  </VRow>
                </template>
                <VRow>
                  <VCol cols="auto">
                    <TTBtn
                      color="tt-secondary"
                      class="mr-6"
                      depressed
                      large
                      data-test="staff-form-button-close"
                      @click="handleClose"
                    >
                      Отменить
                    </TTBtn>
                    <TTBtn
                      large
                      depressed
                      data-test="staff-form-button-save"
                      :disabled="loading || !valid"
                      :loading="loading"
                      @click="save"
                    >
                      Сохранить
                    </TTBtn>
                  </VCol>
                  <VCol v-if="isTestSelectedLocation">
                    <TTAlert
                      title="Сотрудник добавлен в тестовую локацию"
                      content="После публикации локации сотрудник удалится"
                      class="alert pa-0 elevation-0 rounded-lg"
                      width="auto"
                      height="auto"
                    >
                      <template #prepend>
                        <VIcon
                          class="mr-2"
                          color="warning"
                        >
                          $warningAlert
                        </VIcon>
                      </template>
                    </TTAlert>
                  </VCol>
                </VRow>
              </template>
            </VForm>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="1"
        class="text-center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              color="white"
              elevation="1"
              large
              data-test="staff-form-modal-button-close"
              class="mb-2"
              @click="handleClose"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { mask } from 'vue-the-mask';
import cloneDeep from 'lodash/cloneDeep';
import { handleError, validation, watchValidationStatus } from '@/services';
import { formatDate } from '@/utils';

import SDatePicker from '@/components/ui/SDatePicker.vue';
import CtxTimePicker from '@/components/ui/CtxTimePicker.vue';
import UploadAvatar from '@/components/ui/UploadAvatar.vue';
import PhoneNumber from '@/components/ui/PhoneNumber.vue';
import TextTemplate from '@/components/shared/TextTemplate.vue';
import Combobox from '@/components/shared/controls/Combobox.vue';

const defaultUser = {
  id: '',
  last_name: '',
  first_name: '',
  middle_name: '',
  phone: '',
  email: '',
  birthday: '',
  sex: false,
  started: '',
  started_time: '',
  ended: '',
  accepted: '',
  meta: '',
  mentor_id: 0,
  mentor: {},
  hr_id: 0,
  hr: {},
  tutor_id: 0,
  tutor: {},
  position: '',
  photo_base64: undefined,
  photo_url: '',
  location: '',
  level: '',
  progress: '',
  state: '',
  page_link: '',
  is_fired: '',
  potok_id: 0,
  is_auto_login: '',
  created: '',
  is_candidate: true,
  is_tutor: false,
};

export default {
  name: 'StaffForm',
  directives: { mask },
  components: {
    Combobox,
    TextTemplate,
    PhoneNumber,
    SDatePicker,
    CtxTimePicker,
    UploadAvatar,
  },
  props: {
    editUser: {
      type: Object,
      default: () => {
      },
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      validationStatus: [],
      locations: [],
      globalErrorMessage: '',
      isPhoneChecked: false,
      isExists: false,
      loading: false,
      user: {},
      metaData: {},
      accepted: false,
      activeLocation: '',
      dateNow: new Date(),
      hr: {
        search: null,
        list: [],
        loading: false,
      },
      mentor: {
        search: null,
        list: [],
        loading: false,
      },
      tutor: {
        search: null,
        list: [],
        loading: false,
      },
      genders: [
        {
          value: true,
          text: 'Мужской',
        },
        {
          value: false,
          text: 'Женский',
        },
      ],
      tags: [],
      search: '',
      tagsItems: [...Array(10)].map((_, index) => `Text #${index}`),
    };
  },
  computed: {
    showTagInput() {
      return this.$di.ff.get('staff_form_tags');
    },
    meta() {
      const m = this.metaData;
      if (!Object.keys(m)) {
        return { salary: null };
      }
      if (!m.salary) {
        return { salary: null, ...this.metaData };
      }
      return this.metaData;
    },
    locationDisabled() {
      return this.edit && this.editUser.state !== 'INVITED';
    },
    isTestSelectedLocation() {
      return !this.locations.find((location) => location.id === this.activeLocation)?.is_active;
    },
  },
  watch: {
    'hr.search': {
      handler(val) {
        if (val) {
          this.handleSearch('hr', val);
        } else {
          this.handleChange('hr', {});
          this.hr.list = [];
        }
      },
    },
    'mentor.search': {
      handler(val) {
        if (val) {
          this.handleSearch('mentor', val);
        } else {
          this.handleChange('mentor', {});
          this.mentor.list = [];
        }
      },
    },
    'tutor.search': {
      handler(val) {
        if (val) {
          this.handleSearch('tutor', val);
        } else {
          this.handleChange('tutor', {});
          this.tutor.list = [];
        }
      },
    },
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    this.user = cloneDeep(defaultUser);

    if (!this.locationDisabled) {
      this.$repositories.location.list({})
        .then((r) => {
          const { data } = r.data;
          this.locations = data;
        })
        .catch((e) => console.warn(e));
    } else {
      this.locations = [this.editUser.location];
    }
  },
  mounted() {
    if (this.edit) {
      this.handleUser();
      if (this.user.hr && this.user.hr.name) {
        this.handleSearch('hr', this.user.hr.name);
      }
      if (this.user.mentor && this.user.mentor.name) {
        this.handleSearch('mentor', this.user.mentor.name);
      }
      if (this.user.tutor && this.user.tutor.name) {
        this.handleSearch('tutor', this.user.tutor.name);
      }
    }
  },
  methods: {
    formatDate,
    validation,
    handleError,
    async createCheck(phoneNumber) {
      if (this.edit) return false;
      this.user = cloneDeep(defaultUser);
      this.user.phone = phoneNumber;
      this.$repositories.staff.createCheck({ data: { phone: phoneNumber } })
        .then((r) => {
          const { data: check } = r.data;
          this.check = check;
          if (check.state === 'BUSY') {
            let title = '';
            if (check.customer) {
              if (check.customer.is_own) {
                title = 'Сотрудник уже работает в вашей компании';
              } else {
                title = `Сотрудник уже работает в компании '${check.customer.name}'`;
              }
            } else {
              title = 'Сотрудник уже работает в какой-то компании';
            }
            this.$root.$emit('showNotifier', {
              title,
              timeout: 6000,
              type: 'error',
            });
          } else if (check.state === 'NEW') {
            this.isPhoneChecked = true;
            this.user.started = formatDate(Date.now(), 'YYYY-MM-DD');
            this.user.is_candidate = true;
          } else if (check.state === 'FREE') {
            this.isPhoneChecked = true;
            this.isExists = true;
            this.$root.$emit('showNotifier', {
              title: 'Сотрудник найден в базе',
              timeout: 6000,
              type: 'warning',
            });
            const {
              meta,
              phone: tel,
              location,
              ...user
            } = check.staff;
            this.metaData = { ...meta };
            this.user = {
              ...this.user,
              ...user,
              started: formatDate(Date.now(), 'YYYY-MM-DD'),
              is_candidate: true,
            };
          } else {
            this.$root.$emit('showNotifier', {
              title: 'Ошибка сервера',
              timeout: 6000,
              type: 'error',
            });
          }
        })
        .catch((e) => console.warn(e));
      return false;
    },
    fullName(item) {
      return `${item.last_name} ${item.first_name}`;
    },

    handleSearch(type, text) {
      this[type].loading = true;
      this.$repositories.staff.select({
        data: {
          filter: {
            location_id: null,
            is_fired: false,
            search_text: text,
          },
          page_size: 20,
        },
      })
        .then((r) => {
          const { data: { data: staff } } = r;
          this[type].list = [];
          staff.forEach((item) => {
            if (item.id !== this.user.id) {
              this[type].list.push({
                name: item.name,
                id: item.id,
              });
            }
          });
        })
        .catch((e) => {
          console.warn(e);
        })
        .finally(() => {
          this[type].loading = false;
        });
    },

    // сериализовывает входящие данные в требуемые для компонента
    handleUser() {
      const {
        meta,
        location,
      } = this.editUser;
      this.metaData = { ...meta };
      const user = this.editUser;
      this.user = { ...this.user, ...user };
      this.activeLocation = location.id;
    },

    handleLocation(val) {
      this.activeLocation = val;
      this.handleChange('hr', {}, 'hr_id');
      this.handleChange('mentor', {}, 'mentor_id');
      this.handleChange('tutor', {}, 'tutor_id');
    },
    async save() {
      this.loading = true;
      const data = {
        ...this.user,
      };
      data.meta = this.meta;
      if (typeof data.meta.salary === 'string') {
        data.meta.salary = Number(data.meta.salary);
      }
      data.location_id = this.activeLocation;
      if (this.edit) {
        data.updateFields = [
          'location_id', 'last_name', 'first_name', 'middle_name', 'phone', 'birthday', 'sex',
          'deleted', 'started', 'started_time', 'meta', 'position',
          'is_candidate', 'mentor_id', 'tutor_id', 'hr_id', 'comment', 'is_tutor',
        ];
        if (data.email !== this.editUser.email) {
          data.updateFields.push('email');
        }
        if (data.photo_base64) {
          data.updateFields.push('photo_base64');
        }
        data.hr_id = data.hr ? data.hr.id : 0;
        data.mentor_id = data.mentor ? data.mentor.id : 0;
        data.tutor_id = data.tutor ? data.tutor.id : 0;
      }
      data.salary = Number(this.metaData.salary) || null;
      if (!this.edit) {
        Object.keys(data)
          .forEach((i) => {
            if (!data[i]) delete data[i];
          });
        data.hr_id = data.hr ? data.hr.id : null;
        data.mentor_id = data.mentor ? data.mentor.id : null;
        data.tutor_id = data.tutor ? data.tutor.id : null;
        delete data.hr;
        delete data.mentor;
        delete data.tutor;
      }
      try {
        let response;
        if (this.edit || this.isExists) {
          response = await this.$repositories.staff.update({ data });
        } else {
          response = await this.$repositories.staff.create({ data });
        }
        this.$emit('save', {
          user: data,
          response: response.data.data,
        });
      } catch (e) {
        this.handleError(e);
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.activeLocation = null;
      this.$emit('close');
    },

    handleChange(name, value, target) {
      if (name === 'phone') {
        this.isPhoneChecked = false;
      }
      this.user[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },

    handleMetaChange(name, value, target) {
      this.metaData[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
  },
};
</script>

<style scoped lang="scss">
.day-of-week-btns {

  button {
    border-radius: 5px !important;
    background-color: #eee !important;
    border: none;

    &.v-item--active {
      background-color: #cfcfcf !important;
    }
  }
}
</style>
