var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VCombobox',_vm._g(_vm._b({staticClass:"combobox tt-input tt-text-field tt-select",attrs:{"multiple":"","value":_vm.value,"append-icon":"","height":"auto","hide-selected":"","hide-no-data":!_vm.$attrs['search-input'],"hide-details":"","cache-items":"","attach":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('VChip',_vm._b({staticClass:"tt-light-blue--text",attrs:{"input-value":selected,"label":"","color":"tt-light-blue pale","data-test":"tag"}},'VChip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c('VIcon',{staticClass:"cursor--pointer",attrs:{"data-test":"tag-button"},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" fal fa-times ")])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('VChip',{staticClass:"tt-light-blue--text",attrs:{"label":"","color":"tt-light-blue pale","data-test":"tag"}},[_vm._v(" "+_vm._s(item)+" ")])]}},{key:"no-data",fn:function(){return [_c('VListItem',[_c('VListItemContent',[_c('VListItemTitle',[_c('VRow',{attrs:{"justify":"space-between","align":"center"}},[_c('VCol',{attrs:{"cols":"auto"}},[_c('VRow',{attrs:{"align":"center","dense":""}},[_c('VCol',{attrs:{"cols":"auto"}},[_c('span',[_vm._v("Создать")])]),_c('VCol',{attrs:{"cols":"auto"}},[_c('VChip',{staticClass:"tt-light-blue--text",attrs:{"label":"","color":"tt-light-blue pale","data-test":"tag"}},[_vm._v(" "+_vm._s(_vm.$attrs['search-input'])+" ")])],1)],1)],1),_c('VCol',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"tt-light-mono-46--text"},[_vm._v("Enter")])])],1)],1)],1)],1)]},proxy:true}])},'VCombobox',Object.assign({}, _vm.$attrs,_vm.$props),false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }