<template>
  <TTTextField
    v-if="readonly || disabled"
    large
    :value="dateRangeText"
    readonly
    :class="contentClass"
    v-bind="customAttrs"
    :disabled="disabled"
  />
  <VMenu
    v-else
    v-model="menu"
    content-class="v-menu-shadow"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
    @input="menu = false"
  >
    <template #activator="{ on }">
      <TTTextField
        ref="textInput"
        large
        :value="dateRangeText"
        :error-messages="errorMessagesAttr"
        v-bind="customAttrs"
        readonly
        :class="contentClass"
        @click="menu = true"
        @click:append="menu = true"
        v-on="on"
        @keydown.tab="menu = false"
      />
    </template>
    <VCard>
      <VDatePicker
        :value="value"
        :min="computedMinDate"
        :max="computedMaxDate"
        no-title
        range
        v-on="listeners"
        @input="save($event)"
      />
    </VCard>
  </VMenu>
</template>
<script>
import dayjs from 'dayjs';
import { dateToStr, formatDate } from '@/utils';

const MIN_DATE = dayjs('1900-01-01');
const MAX_DATE = dayjs('2100-01-01');

export default {
  name: 'SRangePicker',
  inheritAttrs: false,
  props: {
    value: {
      type: [Array],
      required: true,
    },
    minDate: {
      type: [Date, String],
      default: '',
    },
    maxDate: {
      type: [Date, String],
      default: '',
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => ([]),
    },
    contentClass: {
      type: [String, Object, Array],
      default: '',
    },
  },
  data: () => ({
    errorMessage: '',
    menu: false,
  }),
  computed: {
    dateRangeText() {
      if (this.value.length) {
        const res = this.value.map((item) => formatDate(item));
        return res.join(' - ');
      }
      return '';
    },
    computedMaxDate() {
      if (this.maxDate) {
        return this.dateToStr(this.maxDate);
      }
      return MAX_DATE.format(this.format);
    },
    computedMinDate() {
      if (this.minDate) {
        return this.dateToStr(this.minDate);
      }
      return MIN_DATE.format(this.format);
    },
    customAttrs() {
      const {
        readonly, disabled, format, maxDate, minDate, ...others
      } = this.$attrs;
      return { ...others };
    },
    listeners() {
      const { input, change, ...others } = this.$listeners;
      return { ...others };
    },
    errorMessagesAttr() {
      if (!this.errorMessage) return this.errorMessages;
      if (Array.isArray(this.errorMessages)) {
        return this.errorMessages[0]
          ? this.errorMessages.concat(this.errorMessage) : [this.errorMessage];
      }
      return this.errorMessages ? [this.errorMessages, this.errorMessage] : [this.errorMessage];
    },
  },
  watch: {
    minDate() {
      this.handleMinMaxChange();
    },
    maxDate() {
      this.handleMinMaxChange();
    },
  },
  methods: {
    dateToStr,
    save(v) {
      this.errorMessage = '';
      let value = v;
      if (v.length === 2) {
        if (this.$dayjs(v[0]).isAfter(this.$dayjs(v[1]))) {
          value = [v[1], v[0]];
        }
      }
      this.$emit('input', value);
      this.$refs.textInput.focus();
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
