<template>
  <VRow no-gutters>
    <TTChip
      v-for="(name, i) in showList"
      :key="i"
      :outlined="false"
      color="tt-light-blue pale"
      text-color="tt-blue"
      class="mr-2 mt-1"
      data-test-label="tags-chip"
    >
      {{ name }}
    </TTChip>
    <TTTooltip
      v-if="differentCount"
      bottom
    >
      <template #activator="{ on, attrs }">
        <TTChip
          v-if="differentCount"
          :outlined="false"
          color="tt-light-blue pale"
          text-color="tt-blue"
          class="mr-2 mt-1"
          v-bind="attrs"
          data-test-label="tags-plus-count"
          v-on="on"
        >
          +{{ differentCount }}
        </TTChip>
      </template>
      <span>{{ joinText }}</span>
    </TTTooltip>
  </VRow>
</template>
<script>
export default {
  name: 'Tags',
  props: {
    list: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    showList() {
      return this.list.slice(0, this.limit);
    },
    differentCount() {
      return this.list.length - this.showList.length;
    },
    joinText() {
      return this.list.join(', ');
    },
  },
  watch: {
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>

</style>
