<template>
  <VContainer
    class="pa-0"
    fluid
  >
    <VRow>
      <VCol>
        <p class="mb-0 mr-4 tt-text-title-2">
          Удалить профиль пользователя {{ selectedStaffForDelete }}?
        </p>
      </VCol>
    </VRow>
    <template v-if="!loading">
      <div v-if="hasNetwork">
        <VRow class="align-end">
          <VCol
            cols="12"
            class="pb-1"
          >
            <p class="pb-2">
              Сотрудник является наставником или HR, либо его контакт используется в контенте.
            </p>
            <p class="mb-0">
              Вы можете посмотреть связи пользователя или выбрать сотрудника,
              на которого будут перенесены функции HR’а и наставника,
              а также упоминания контакта в контенте после удаления профиля.
            </p>
          </VCol>
          <VCol>
            <TTAutocomplete
              label="Сотрудник"
              placeholder="Выберите сотрудника"
              no-rotate
              large
              :value="replaceId"
              :loading="autocompleteLoading"
              :items="staffList"
              :search-input.sync="search"
              hide-no-data
              item-text="name"
              item-value="id"
              hide-details="auto"
              single-line
              data-test="delete-staff-form-replace-autocomplete"
              :class="{'error--text' : globalErrorMessage}"
              @change="replaceId = $event"
            />
            <div
              v-if="globalErrorMessage"
              class="error--text mt-2"
            >
              Чтобы удалить пользователя, необходимо выбрать сотрудника для замены
            </div>
          </VCol>
        </VRow>
      </div>
      <p
        v-else
        class="pa-0 mb-3"
      >
        Профиль пользователя {{ selectedStaffForDelete }} и все данные пользователя будут удалены из системы.
      </p>
      <div class="d-flex justify-end pt-4">
        <TTBtn
          v-if="!hasNetwork"
          large
          color="tt-secondary"
          depressed
          data-test="delete-staff-form-button-cancel"
          @click="handleClose"
        >
          Отменить
        </TTBtn>
        <TTBtn
          v-else
          color="tt-secondary"
          large
          data-test="delete-staff-form-goto-staff-item"
          depressed
          link
          :to="networkLink"
        >
          Посмотреть связи
        </TTBtn>
        <TTBtn
          large
          class="white--text ml-3"
          depressed
          color="tt-danger"
          data-test="delete-staff-form-button-submit"
          :loading="deleting"
          @click="handleDelete"
        >
          {{ hasNetwork ? 'Заменить и удалить' : 'Удалить' }}
        </TTBtn>
      </div>
    </template>
    <VRow v-else>
      <VCol align="center">
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VCol>
    </VRow>
    <TTBtn
      absolute
      top
      right
      data-test="delete-staff-form-button-close"
      width="20"
      height="20"
      min-width="20"
      class="pa-0"
      depressed
      color="transparent"
      @click="handleClose"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-times
      </VIcon>
    </TTBtn>
  </VContainer>
</template>

<script>
import { handleError } from '@/services';

export default {
  name: 'DeleteStaffForm',
  props: {
    staff: {
      type: Object,
      required: true,
    },
    deleting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasNetwork: false,
      loading: false,
      autocompleteLoading: false,
      staffList: [],
      search: null,
      replaceId: null,
      globalErrorMessage: '',
    };
  },
  computed: {
    staffId() {
      return this.staff.id;
    },
    networkLink() {
      return `/staff/${this.staffId}#tab=network`;
    },
    selectedStaffForDelete() {
      return `${this.staff.first_name} ${this.staff.last_name}`;
    },
  },
  watch: {
    search: {
      handler(val) {
        if (val) {
          this.handleSearch(val);
        } else {
          this.staffList = [];
        }
      },
    },
  },
  created() {
    this.checkUserNetwork();
  },
  methods: {
    handleError,
    async checkUserNetwork() {
      try {
        this.loading = true;
        this.hasNetwork = false;
        const { data } = await this.$repositories.staff.hasNetwork(this.staffId);
        this.hasNetwork = data.data;
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
    handleDelete() {
      this.$emit('delete', this.replaceId);
    },
    handleSearch(text) {
      this.autocompleteLoading = true;
      this.$repositories.staff.select({
        data: {
          filter: {
            location_id: null,
            is_fired: false,
            search_text: text,
          },
          page_size: 20,
        },
      })
        .then((r) => {
          const { data: { data: staff } } = r;
          this.staffList = staff.filter((item) => item.id !== this.staff.id);
        })
        .catch((e) => {
          console.warn(e);
        })
        .finally(() => {
          this.autocompleteLoading = false;
        });
    },
    handleClose() {
      this.replaceId = null;
      this.$emit('cancel');
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
