<template>
  <VCombobox
    multiple
    :value="value"
    v-bind="{...$attrs,...$props}"
    append-icon=""
    height="auto"
    class="combobox tt-input tt-text-field tt-select"
    hide-selected
    :hide-no-data="!$attrs['search-input']"
    hide-details
    cache-items
    attach
    v-on="$listeners"
  >
    <template #selection="{ attrs, item, parent, selected }">
      <VChip
        v-bind="attrs"
        :input-value="selected"
        label
        color="tt-light-blue pale"
        class="tt-light-blue--text"
        data-test="tag"
      >
        <span class="pr-2">
          {{ item }}
        </span>
        <VIcon
          class="cursor--pointer"
          data-test="tag-button"
          @click="parent.selectItem(item)"
        >
          fal fa-times
        </VIcon>
      </VChip>
    </template>
    <template #item="{item}">
      <VChip
        label
        color="tt-light-blue pale"
        class="tt-light-blue--text"
        data-test="tag"
      >
        {{ item }}
      </VChip>
    </template>
    <template #no-data>
      <VListItem>
        <VListItemContent>
          <VListItemTitle>
            <VRow
              justify="space-between"
              align="center"
            >
              <VCol cols="auto">
                <VRow
                  align="center"
                  dense
                >
                  <VCol cols="auto">
                    <span>Создать</span>
                  </VCol>
                  <VCol cols="auto">
                    <VChip
                      label
                      color="tt-light-blue pale"
                      class="tt-light-blue--text"
                      data-test="tag"
                    >
                      {{ $attrs['search-input'] }}
                    </VChip>
                  </VCol>
                </VRow>
              </VCol>
              <VCol cols="auto">
                <span class="tt-light-mono-46--text">Enter</span>
              </VCol>
            </VRow>
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
    </template>
  </VCombobox>
</template>

<script>
export default {
  name: 'Combobox',
  props: {
    value: {
      type: [String, Object, Array],
      default: '',
    },
  },

};
</script>

<style scoped lang="scss">
.combobox {
  padding: 0;
  margin: 0;

  ::v-deep {
    .v-input {
      &__append-inner {
        padding-right: 12px;

        i {
          color: map-get($tt-light-mono-46, 'base')
        }

      }
    }

    input {
      position: relative;
    }
  }
}

</style>
