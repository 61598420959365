<template>
  <div>
    <div class="phone-number">
      <TTTextField
        v-model="phoneNumber"
        v-mask="'##################'"
        large
        class="input-phone"
        :class="{'error--text' : error}"
        :append-icon="error ? 'fas fa-exclamation-circle' : ''"
        data-test="staff-phone-input"
        type="number"
        @paste.prevent.stop="handlePaste"
      />
    </div>
    <div>
      <div
        v-if="error"
        class="v-messages error--text mt-2"
        role="alert"
        data-test="push-form-error-text-day"
      >
        <div class="v-messages__wrapper">
          <p class="v-messages__message">
            {{ errorMessages }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { mask } from 'vue-the-mask';
import { getPasteText } from '@/utils/clipboard';

export default {
  name: 'PhoneNumber',
  directives: { mask },
  props: {
    attach: {
      type: [String, Boolean],
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => ([]),
    },
  },
  data() {
    return {
      phoneNumber: '',
    };
  },
  computed: {
    clearPhone() {
      return this.phoneNumber.replace(/\D/g, '');
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val && val !== this.clearPhone) {
          this.phoneNumber = val;
        }
      },
      immediate: true,
    },
    phoneNumber() {
      const { clearPhone } = this;
      const parse = parsePhoneNumberFromString(`+${clearPhone}`);
      this.$emit('input', clearPhone);
      if (parse && (parse?.isValid() || /^7600/.test(clearPhone))) {
        this.$emit('change', clearPhone);
      }
    },
  },
  methods: {
    handlePaste(e) {
      const pastedText = getPasteText(e);
      this.phoneNumber = pastedText?.replace(/\D/g, '');
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-number {
  .input-phone {
    position: relative;
    &::before {
      content: "+";
      font-size: 16px;
      line-height: 16px;
      color: map-deep-get($tt-light-mono-100, 'base');
      position: absolute;
      top: calc(50% - 8px);
      left: 3px;
      z-index: 1;
    }
  }
}
</style>
